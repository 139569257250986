import React from "react";

import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaRegPaperPlane,
  FaLinkedin,
  FaMapMarked,
  FaMailchimp,
  FaMailBulk,
  FaTelegram
} from "react-icons/fa";

import {
  BsTelegram
} from 'react-icons/bs'

import {
  GoLocation
} from 'react-icons/go'
import Logo from '../../assets/logo/JEWELERCOIN.png'
const Footer = () => {
  return (
    <div className="w-full mt-24 text-white py-y px-2 shadow-lg text-white" style={{'backgroundColor':'#242424'}}>
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-4 border-b-2 border-gray-600 py-8">
        <div>
          
        
        <img src={Logo} style={{'width':'250px','height':'180px'}}>
          </img>
          <p className="text-white">
            Jewelercoin is a Multi-blockchain token. It has given priority to trust and innovation.
          </p>
         </div>
         <div>
          <h6 className="font-bold uppercase text-center">Jewelercoin</h6>
          <ul className="text-center">
            <li className="py-1"><a href="https://jewelercoin.org/" target="_blank" rel="noreferrer">What is Jewelercoin</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/how-it-works/" target="_blank" rel="noreferrer"> How It Works</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/how-it-works/" target="_blank" rel="noreferrer"> Roadmap</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/news/" target="_blank" rel="noreferrer"> News</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/faqs/" target="_blank" rel="noreferrer"> FAQs</a></li>
          </ul>
          </div>
          {/* <div>
            <h1 className="text-center">Projects</h1>
          </div> */}
        <div>
          <h6 className="font-bold uppercase pt-2 text-center">Projects</h6>
          <ul className="text-center">
            <li className="py-1"><a href="https://jewelercoin.org/projects/" target="_blank" rel="noreferrer">J VR Metaverse</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/projects/" target="_blank" rel="noreferrer">J NFT Marketplace</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/projects/" target="_blank" rel="noreferrer">J GOLD Stabile Coin</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/projects/" target="_blank" rel="noreferrer">Blokchain</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/projects/" target="_blank" rel="noreferrer">Exchanges</a></li>

          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2 text-center">Company</h6>
          <ul className="text-center">
            <li className="py-1"><a href="https://jewelercoin.org/about/" target="_blank" rel="noreferrer">About Us</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/contact/" target="_blank" rel="noreferrer">Contact Us</a></li>
            <li className="py-1"><a href="https://jewelercoin.org/careers/" target="_blank" rel="noreferrer">Careers</a></li>

          </ul>
        </div>
       
      </div>
      <div className="max-w-[1240px] mx-auto text-white grid grid-cols-1 md:grid-cols-3 border-b-2 border-gray-600 py-8">
        
        <div className="text-center">
        <GoLocation/>
          <p>
            Jeweler Software
            </p>
        </div>
        <div>
        <FaMailBulk/>
        <p>
          info@jewelercoin.org
        </p>
        </div>
       
        <div className="text-center">
          <FaTelegram/>
        <p>
          https://t.me/jewelercoin
        </p>
        </div>
      </div>
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-white">
        <p className="py-4">
          &copy; {new Date().getFullYear()} All rights reserved
        </p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          <a href="https://www.facebook.com/100087629870450/">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/jewelercoin">
            <FaTwitter />
          </a>
          <a href="https://github.com/JewelerSoftware">
            <FaGithub />
          </a>
          <a href="https://t.me/jewelercoin">
            <FaRegPaperPlane />
          </a>
        </div>
        
      </div> 
      
    </div>
  );
};

export default Footer;
