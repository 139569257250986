import React, {  useContext, useState } from "react";
// import CountDown from "./CountDown";
import { AiFillDollarCircle } from "react-icons/ai";
import TimeComponent from "../Timer/TimeComponent";
import { PresaleContext }  from '../../context/PresaleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { BigNumber, ethers } from 'ethers';
import { Audio, Oval, ColorRing } from  'react-loader-spinner'
//import TokenomicsDescription from "../Token/TokenomicsDescription";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const CoinDescription = () => {
  const { 
    isPresaleActive,
    isPresalePaused,
    isConnected, 
    connectWallet, 
    buyTokenWithBNB, 
    buyTokenWithETH,
    connectedAccount, 
    totalTokensBoughtByInvestor,
    totalBNBInvestedByInvestor,
    totalTokensClaimedByInvestor,
    pricePerToken,
    presaleDuration,
    minPurchaseInWei,
    maxPurchaseInWei,
    totalTokensPurchased,
    totalUSDRaised,
    totalBNBRaised,
    totalUSDTRaised,
    totalTokensToBeSold,
    isPresaleClaimsOpen,
    isAnActiveInvestor,
    claimFromBEP20Contribution,
    claimFromERC20Contribution,
    totalUSDTContributedByInvestor,
    approveBEP20BUSDStableCoin,
    approveBEP20USDTStableCoin,
    isBEP20PresaleActive,
    isERC20PresalePaused,
    isBEP20PresalePaused,
    isERC20PresaleActive,
    totalUSDRaisedERC20,
    totalUSDRaisedBEP20,
    totalUSDTRaisedERC20,
    totalUSDTRaisedBEP20,
    totalTokensPurchasedBEP20,
    totalTokensPurchasedERC20,
    totalTokensClaimedERC20, 
    totalTokensClaimedBEP20,
    isPresaleClaimsOpenERC20,
    isPresaleClaimsOpenBEP20,
    isAnActiveInvestorBEP20, 
    isAnActiveInvestorERC20,
    totalTokensBoughtByInvestorBEP20, 
    totalTokensBoughtByInvestorERC20,
    totalBNBInvestedByInvestorERC20, 
    totalBNBInvestedByInvestorBEP20,
    totalTokensClaimedByInvestorERC20,
    totalTokensClaimedByInvestorBEP20,
    totalUSDTContributedByInvestorERC20,
    totalUSDTContributedByInvestorBEP20,
    isAllowedToClaimTokensBEP20,
    isAllowedToClaimTokensERC20,
    totalERC20BNBRaised,
    totalBEP20BNBRaised,
    totalInvestors,
    approveERC20USDTStableCoin,
    approveERC20BUSDStableCoin,
    truncateAddress, } = useContext(PresaleContext);

    const BEP20_USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
    const BEP20_BUSD_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

    const ERC20_USDT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
    const ERC20_BUSD_ADDRESS = '0x4Fabb145d64652a948d72533023f6E7A623C7C53'


  const [isApprovalComplete, setApprovalComplete] = useState(false)
  const [isPurchaseComplete, setIsPurchaseComplete] = useState(false)
  const [showApprovalProgress, setShowApprovalProgress] = useState(false)

  const showLoader = async() =>{
  
    <ColorRing
      visible={true}
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#b8c480', '#B2A3B5', '#F4442E', '#51E5FF', '#429EA6']}
    />

  }
  
  const handleBNBSubmit = async(event) => {
    event.preventDefault()
    const amount = event.target.amount.value;
    const t = ethers.utils.parseEther(amount)
    //console.log(t)
    buyTokenWithBNB(t);
    event.target.amount.value = ""
  }

  const handleETHSubmit = async(event) => {
    event.preventDefault()
    const amount = event.target.amount.value;
    const t = ethers.utils.parseEther(amount)
    //console.log(t)
    buyTokenWithETH(t);
    event.target.amount.value = ""
  }

  const performBEP20StableCoinApproval = async(event) =>{
    event.preventDefault()
    const jewelerBep20PresaleAddress = '0x7238111B514870941fA8a737Fff0AC7750448FA4'
    const amount = event.target.stableCoinAmount.value;
    const coin = event.target.stableCoinName.value;
    const t = ethers.utils.parseEther(amount)
    if(coin === BEP20_USDT_ADDRESS){
      approveBEP20USDTStableCoin(jewelerBep20PresaleAddress,t) 
      event.target.stableCoinAmount.value = "" 
    }else if(coin === BEP20_BUSD_ADDRESS){
      approveBEP20BUSDStableCoin(jewelerBep20PresaleAddress, t)
      event.target.stableCoinAmount.value = ""
    }else{ 
      return false
    }  
  }

  const performERC20StableCoinApproval = async(event) =>{
    event.preventDefault()
    const jewelerERC20PresaleAddress = '0x7238111B514870941fA8a737Fff0AC7750448FA4'
    const amount = event.target.stableCoinAmount.value;
    const coin = event.target.stableCoinName.value;
    const t = ethers.utils.parseEther(amount)
    if(coin === ERC20_USDT_ADDRESS){
      approveERC20USDTStableCoin(jewelerERC20PresaleAddress,t) 
      event.target.stableCoinAmount.value = "" 
    }else if(coin === ERC20_BUSD_ADDRESS){
      approveERC20BUSDStableCoin(jewelerERC20PresaleAddress, t)
      event.target.stableCoinAmount.value = ""
    }else{ 
      return false
    }  
  }
  //bg-slate-900 style={{'backgroundColor':'#E8F50F'}}
  return (
    <>
      <div name="about" className="w-full bg-opacity-100 text-black" style={{'backgroundColor':'#FFD900'}}>
        <div className="max-w-[1240px] mx-auto">
          <div className="text-center">
            <h2 className="text-5xl font-bold">Jewelercoin Presale</h2>
              <h2 className="text-5xl font-bold">($JEWEL)</h2>
            <div className="border py-1 m-5 rounded-xl shadow-xl h-96">
              <div className="countdown-timer-card">
              {
              (isBEP20PresaleActive || isERC20PresaleActive)&& <h2 className="text-black">PRESALE ENDS IN...</h2>
            }
              {
                (isBEP20PresaleActive || isERC20PresaleActive) && presaleDuration ? 

                <TimeComponent />

                :
               <p className="text-black">Loading Timer...</p>
              }
              <p p className="text-black py-2">
                      {(isBEP20PresaleActive || isERC20PresaleActive) && 'Presale Price: 1 $JEWEL :  '}{ (isBEP20PresaleActive || isERC20PresaleActive) && pricePerToken ? pricePerToken /100 : '' }{'    '}{ isBEP20PresaleActive && 'USD'}
                      <br></br>
                      {(isBEP20PresaleActive || isERC20PresaleActive) && 'Minimum Contribution (USD) :  '}{ (isBEP20PresaleActive || isERC20PresaleActive)&& minPurchaseInWei ? minPurchaseInWei : '' }{'    '}{ isBEP20PresaleActive && 'USD'}
                      <br></br>
                      {(isBEP20PresaleActive || isERC20PresaleActive)&& 'Maximum Contribution (USD) :  '}{ (isBEP20PresaleActive || isERC20PresaleActive)&& maxPurchaseInWei ? maxPurchaseInWei : '' }{'    '}{ isBEP20PresaleActive && 'USD'}
                      {/* <br></br> */}
                      <br></br>
                      {isBEP20PresaleActive && 'Total BNB Raised :  '}{ isBEP20PresaleActive && totalBEP20BNBRaised ? totalBEP20BNBRaised : '' }{'    '}{ isBEP20PresaleActive && 'BNB'}
                      <br></br>
                      {isERC20PresaleActive && 'Total ETH Raised : '}{ isERC20PresaleActive && totalERC20BNBRaised ? totalERC20BNBRaised : ''}{'  '}{ isERC20PresaleActive && 'ETH' }
                      <br></br>
                      {isBEP20PresaleActive && 'Total BEP20 USDT Raised :  '}{ isBEP20PresaleActive && totalUSDTRaisedBEP20 ? totalUSDTRaisedBEP20 : '' }{'    '}{ isBEP20PresaleActive && 'USDT'}
                      <br></br>
                      {isERC20PresaleActive && 'Total ERC20 USDT Raised :  '}{ isERC20PresaleActive && totalUSDTRaisedERC20 ? totalUSDTRaisedERC20 : 'Loading...' }{'    '}{ isERC20PresaleActive && 'USDT'}
                      <br></br>
                      {(isBEP20PresaleActive || isERC20PresaleActive)&& 'Total Investors : '}{ (isBEP20PresaleActive || isERC20PresaleActive) && totalInvestors ? totalInvestors: ''}
              </p>
               
              </div>
              </div>
              </div>
            </div>
              
            <br></br>
            {/* <p className="text-1xl py-2 text-white">
              To help fund the project, we humbly invite you to take part in our first ICO by purchasing some $AJP tokens
            </p> */}
          </div>
       
          <div className="grid md:grid-cols-4 gap-1 px-2 text-center" style={{'backgroundColor':'#242424'}}>
          <div className="border py-7 m-5 rounded-xl shadow-xl h-96">
              <div className="countdown-timer-card">
                <div>
                  <h2 className="text-3xl font-bold uppercase py-1 text-white">
                    Buy With BNB
                  </h2>
                  <br></br>
                 
                </div>
              </div>
              <div className="countdown-timer-card">
                <div className="countdown-timer-card__timer">
                  <form onSubmit={handleBNBSubmit}>
                    <p className="py-3">
                    <span className="text-white">Enter BNB Amount: </span>
                    <input
                      type="text"
                      name="amount"
                      required
                      placeholder="Enter BNB Amount"
                      className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2"
                    />
                    </p>
                      {
                        isConnected && isBEP20PresaleActive && !isBEP20PresalePaused &&
                        <p className="py-2">
                        <button type="submit" className="bg-indigo-600 text-white px-4 py-2 rounded-xl">
                          Contribute
                        </button>
                        </p>
                      }
                      
                  </form>
                  {
                        !isConnected &&
                        <p className="py-2">
                        <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" onClick={connectWallet}>
                        <FontAwesomeIcon icon={solid('wallet')}/> Connect Wallet To Buy
                        </button>
                        </p>
                      }
                 {/* <p className="text-white">
                      Unvested tokens to be claimed a week after DEX Listing on the 16th of April.
                 </p>
                 <p className="text-white">
                      Vested tokens will be claimed after lock duration ends.
                 </p> */}
                </div>
              </div>
            </div>
            <div className="border py-7 m-5 rounded-xl shadow-xl h-96" >
              {/* account profile and balcnce here  */}
              <div className="justify-center">
                <p className="text-2xl font-bold text-white uppercase py-0">Buy with BEP20 Stablecoins</p>
                {/* <hr></hr> */}
                <form onSubmit={performBEP20StableCoinApproval}>
                 <p className="py-6"> 
                <span className="text-white" >Select preferred stable coin:     </span>
                 <select name="stableCoinName" className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2">
                          <option value="0x55d398326f99059fF775485246999027B3197955">USDT</option>
                          <option value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">BUSD</option>
                    </select>
                  </p>
                  <p>
                    <input
                      type="text"
                      name="stableCoinAmount"
                      required
                      placeholder="Enter Stablecoin Amount"
                      className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2"
                    />
                 </p>
                 {/* isApprovalComplete */}
                      <p>
                      {
                        isConnected && isBEP20PresaleActive && !isBEP20PresalePaused &&
                        <p className="py-3">
                        <button type="submit" className="bg-indigo-600 text-white px-4 py-2 rounded-xl">
                          Approve and Buy
                        </button>
                        </p>
                      }

                      </p>
                      
                  </form>
                  {
                        !isConnected &&
                        <p className="py-3">
                        <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" onClick={connectWallet}>
                        <FontAwesomeIcon icon={solid('wallet')}/> Connect Wallet To Buy
                        </button>
                        </p>
                      }
                      {/* {
                  isConnected && isPresaleStarted && isPresaleOpenForClaims && !isPresalePaused && isActiveInvestor &&
                    <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl"> 
                       Claim From Stablecoin Contribution
                    </button>  
                } */}
                {/* <p className="text-white py-1">SPECIAL OFFER(STABLECOINs ONLY)</p>
                <p className="text-white py-1">1 AJP = $0.03 USD</p>
                <p className="text-white py-0">Minimum Contribution: $3 USD</p> */}
                      {/* {
                      canShowProgressBar &&
                        <div className="text-center">
                           <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#b8c480', '#B2A3B5', '#F4442E', '#51E5FF', '#429EA6']}
                        />
                        </div>
                     } */}
              </div>
            </div>
            <div className="border py-7 m-5 rounded-xl shadow-xl h-96">
              <div className="countdown-timer-card">
                <div>
                  <h2 className="text-3xl font-bold uppercase py-1 text-white">
                    Buy With ETH
                  </h2>
                  <br></br>
                 
                </div>
              </div>
              <div className="countdown-timer-card">
                <div className="countdown-timer-card__timer">
                  <form onSubmit={handleETHSubmit}>
                    <p className="py-3">
                    <span className="text-white">Enter ETH Amount: </span>
                    <input
                      type="text"
                      name="amount"
                      required
                      placeholder="Enter ETH Amount"
                      className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2"
                    />
                    </p>
                      {
                        isConnected && isERC20PresaleActive && !isERC20PresalePaused &&
                        <p className="py-2">
                        <button type="submit" className="bg-indigo-600 text-white px-4 py-2 rounded-xl">
                          Contribute
                        </button>
                        </p>
                      }
                      
                  </form>
                  {
                        !isConnected &&
                        <p className="py-2">
                        <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" onClick={connectWallet}>
                        <FontAwesomeIcon icon={solid('wallet')}/> Connect Wallet To Buy
                        </button>
                        </p>
                      }
                 {/* <p className="text-white">
                      Unvested tokens to be claimed a week after DEX Listing on the 16th of April.
                 </p>
                 <p className="text-white">
                      Vested tokens will be claimed after lock duration ends.
                 </p> */}
                </div>
              </div>
            </div>
          
            {
              /*
                PRESALE STATISTICS TAKEN OVER BY STABLECOIN PURCHASE
              */
            }
            
            <div className="border py-7 m-5 rounded-xl shadow-xl h-96">
              {/* account profile and balcnce here  */}
              <div className="justify-center">
                <p className="text-2xl font-bold text-white uppercase py-0">Buy with ERC20 Stablecoins</p>
                {/* <hr></hr> */}
                <form onSubmit={performERC20StableCoinApproval}>
                 <p className="py-6"> 
                <span className="text-white" >Select preferred stable coin:     </span>
                 <select name="stableCoinName" className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2">
                          <option value="0xdAC17F958D2ee523a2206206994597C13D831ec7">USDT</option>
                          <option value="0x4Fabb145d64652a948d72533023f6E7A623C7C53">BUSD</option>
                          {/* <option value="0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d">USDC</option> */}
                          {/* <option value="0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3">DAI</option> */}
                          
                    </select>
                  </p>
                  <p>
                    <input
                      type="text"
                      name="stableCoinAmount"
                      required
                      placeholder="Enter Stablecoin Amount"
                      className="border-2 border-gray-300 bg-white h-10 rounded-lg text-sm focus:outline-none p-2"
                    />
                 </p>
                 {/* isApprovalComplete */}
                      <p>
                      {
                        isConnected && isERC20PresaleActive && !isERC20PresalePaused &&
                        <p className="py-3">
                        <button type="submit" className="bg-indigo-600 text-white px-4 py-2 rounded-xl">
                          Approve and Buy
                        </button>
                        </p>
                      }

                      </p>
                      
                  </form>
                  {
                        !isConnected &&
                        <p className="py-3">
                        <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" onClick={connectWallet}>
                        <FontAwesomeIcon icon={solid('wallet')}/> Connect Wallet To Buy
                        </button>
                        </p>
                      }
                      {/* {
                  isConnected && isPresaleStarted && isPresaleOpenForClaims && !isPresalePaused && isActiveInvestor &&
                    <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl"> 
                       Claim From Stablecoin Contribution
                    </button>  
                } */}
                {/* <p className="text-white py-1">SPECIAL OFFER(STABLECOINs ONLY)</p>
                <p className="text-white py-1">1 AJP = $0.03 USD</p>
                <p className="text-white py-0">Minimum Contribution: $3 USD</p> */}
                      {/* {
                      canShowProgressBar &&
                        <div className="text-center">
                           <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#b8c480', '#B2A3B5', '#F4442E', '#51E5FF', '#429EA6']}
                        />
                        </div>
                     } */}
              </div>
            </div>
            
       
          </div>
        
      
          <div className="grid md:grid-cols-2 gap-1 px-2 text-center bg-opacity-75" style={{'backgroundColor':'#FFD900'}}>
          <div className="border py-1 m-5 rounded-xl shadow-xl h-96" style={{'backgroundColor':'#232424'}}>
             
             <div className="justify-center" >
               <AiFillDollarCircle className="text-6xl text-indigo-600" />
               <p className="text-2xl font-bold text-white uppercase py-0">My Contribution(ERC20)</p>
               <p></p>
               <hr></hr>
               <p className="text-white">
                 Account: 
                     { isConnected && connectedAccount !== null &&
                     
                     truncateAddress(connectedAccount)
                     
                     }
               </p>
               <p className="text-white py-1">Total $JEWEL Purchased: { isConnected && totalTokensBoughtByInvestorERC20 != null ? totalTokensBoughtByInvestorERC20 : 0 } $JEWEL </p>
               <p className="text-white py-1">Total ETH Spent: { isConnected && totalBNBInvestedByInvestorERC20 != null ? totalBNBInvestedByInvestorERC20: 0 } ETH</p>
               <p className="text-white py-1">Total USDT Spent: { isConnected && totalUSDTContributedByInvestorERC20 != null ? totalUSDTContributedByInvestorERC20: 0 } USDT</p>
               <p className="text-white py-5">Total $JEWEL Claimed: { isConnected && totalTokensClaimedByInvestorERC20 != null ? totalTokensClaimedByInvestorERC20 : 0 } $JEWEL </p>
             
               {
                  isConnected && isPresaleClaimsOpenERC20 && !isERC20PresalePaused && isAnActiveInvestorERC20 && isAllowedToClaimTokensERC20 ?
                    <button className="bg-indigo-600 text-white px-4 py-1 rounded-xl" onClick={claimFromERC20Contribution}> 
                       Claim From ERC20 Contribution
                    </button>
                    
                    :
                    !isPresaleClaimsOpenERC20 &&
                    <>
                    
                    <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" disabled>Claims Closed</button>
                    
                    </>
                }
               <br></br>
               <br></br>
              
             </div>
           </div>
           <div className="border py-1 m-5 rounded-xl shadow-xl h-96" style={{'backgroundColor':'#242424'}}>
            
            <div className="justify-center">
              <AiFillDollarCircle className="text-6xl text-indigo-600" />
              <p className="text-2xl font-bold text-white uppercase py-0">My Contribution(BEP20)</p>
              <p></p>
              <hr></hr>
              <p className="text-white">
                Account: 
                    { isConnected && connectedAccount !== null &&
                    
                    truncateAddress(connectedAccount)
                    
                    }
              </p>
              <p className="text-white py-1">Total $JEWEL Purchased: { isConnected && totalTokensBoughtByInvestorBEP20 != null ? totalTokensBoughtByInvestorBEP20 : 0 } $JEWEL </p>
              <p className="text-white py-1">Total BNB Spent: { isConnected && totalBNBInvestedByInvestorBEP20 != null ? totalBNBInvestedByInvestorBEP20: 0 } BNB</p>
              <p className="text-white py-1">Total USDT Spent: { isConnected && totalUSDTContributedByInvestorBEP20 != null ? totalUSDTContributedByInvestorBEP20: 0 } USDT</p>
              <p className="text-white py-5">Total $JEWEL Claimed: { isConnected && totalTokensClaimedByInvestorBEP20 != null ? totalTokensClaimedByInvestorBEP20 : '' } $JEWEL </p>
              {
                  isConnected && isPresaleClaimsOpenBEP20 && !isBEP20PresalePaused && isAnActiveInvestorBEP20 && isAllowedToClaimTokensBEP20?
                    <button className="bg-indigo-600 text-white px-4 py-1 rounded-xl" onClick={claimFromBEP20Contribution}> 
                       Claim From BEP20 Contribution
                    </button>
                    
                    :
                    !isPresaleClaimsOpenBEP20 &&
                    <>
                    
                    <button className="bg-indigo-600 text-white px-4 py-2 rounded-xl" disabled>Claims Closed</button>
                    
                    </>
                }
              
              <br></br>
              <br></br>
             
            </div>
          </div>
          </div>
    

    
             
                    
   
  
                   
                
                 
      
    </>
  );
};

export default CoinDescription;
