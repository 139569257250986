import React, { useState, useContext } from "react";
import { PresaleContext }  from '../../context/PresaleContext';
import { Link } from "react-router-dom";

import { MenuIcon, XIcon } from "@heroicons/react/outline";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import Logo from '../../assets/logo/JEWELERCOIN.png'
const Navbar = () => {
  const { isConnected, connectWallet, disconnectWallet, 
    chainId, switchBinanceSmartChainNetwork,switchToEthereumNetwork,
    account, disconnect, toHex } = useContext(PresaleContext);

  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  return (
    <div className="w-screen h-[80px] z-10 text-gray-300 fixed top-0 shadow-lg" style={{'backgroundColor':'#242424'}}>
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <img src={Logo} style={{'width': '250px','height':'150px'}}>
          </img>
          {/* <h1 className="text-3xl font-bold mr-4 sm:text-4xl"><span className="text-yellow-600">JEWELERCOIN</span></h1> */}
          {/* <ul className="hidden md:flex cursor-pointer">
            <li>
              <Link to="/" smooth={true} duration={500}>
                Presale
              </Link>
            </li>
          </ul> */}
        </div>
        <div className="hidden md:flex pr-4">
          { !isConnected ?
          <><button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={connectWallet}>

              <FontAwesomeIcon icon={solid('wallet')} /> Connect Wallet
            </button><>
                
              </></>
          :
          <>
          
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={disconnectWallet}>
              <FontAwesomeIcon icon={solid('wallet')} /> Disconnect Wallet
            </button>
          </>
        }
        {  isConnected && chainId !== null  && chainId !== 1   ?
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={switchBinanceSmartChainNetwork}>Switch To Binance Smart Chain To Buy BEP20</button>
          :
          <></>
        }
         {  isConnected && chainId !== null  && chainId !== 56   ?
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={switchToEthereumNetwork}>Switch To Ethereum Mainnet To Buy ERC20</button>
          :
          <></>
        }
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute  w-full px-8"} style={{'backgroundColor':'#232424'}}>
        {/* <li className="w-full text-white cursor-pointer">
          <Link onClick={handleClose} to="/" smooth={true} duration={500}>
          Presale
          </Link>
        </li> */}
        
        <div className="flex flex-col my-4">
        { !isConnected ?
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={connectWallet}>
            <FontAwesomeIcon icon={solid('wallet')}/> Connect Wallet
          </button>
          :
          
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={disconnectWallet}>
            <FontAwesomeIcon icon={solid('wallet')}/> Disconnect Wallet
          </button>
        }
         { isConnected && chainId != null  && chainId !== 56 ?
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={switchBinanceSmartChainNetwork}>Switch To Binance Smart Chain To Buy BEP20</button>
          :
          <></>
        }
        { isConnected && chainId != null  && chainId !== 1 ?
          <button className="border bg-transparent px-2 py-2 text-white mr-4" onClick={switchToEthereumNetwork}>Switch To Ethereum Mainnet To Buy ERC20</button>
          :
          <></>
        }
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
