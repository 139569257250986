import React from "react";
//import PresaleContext  from '/src/context/PresaleContext';

import bgImg from "../../assets/cyber-bg.png";
import { Link } from "react-router-dom";
import GIF from '../../assets/logo/donen.gif'
import Metaverse from '../../assets/logo/jewel_metaverse.png'
import JewelMetaverse from '../../assets/logo/jewel_metaverse_2.jpg'
const Hero = () => {
  return (
    <div //bg-slate-900 #232424
      name="home"
      className="w-full h-screen flex flex-col justify-between"
    style={{'backgroundColor':'#242424'}}>
      <div className="grid md:grid-cols-2  m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-1">
          <p className="text-2xl text-white"></p>
          <h2 className="py-1 text-5xl md:text-7xl font-bold text-white uppercase mb-2.5" >
           
           JEWELERCOIN
           
          </h2>
          <h2 className="text-2xl text-white">
          
            Trade Oriented
            </h2>
            <p className="text-white py-1">Jewelercoin was founded to bring innovations to Metaverse and Stable coins. With these projects, It aimed to connect many people to itself and to reach every individual that the blockchain system did not reach.</p>
            <p>
            <a href="https://www.jewelercoin.org/" target="_blank" rel="noreferrer">
            <button className="py-3 px-6 my-4 bg-white text-black hover:bg-purple-600 hover:border-gray-300 mx-px" style={{'hover':'#750778'}}>Home</button>
            </a>
            <a href="https://www.youtube.com/watch?v=fxik-KQ58PQ" target="_blank" rel="noreferrer">
            <button className="py-3 px-6 my-4 bg-white text-black hover:bg-purple-600 hover:border-gray-300 mx-px" style={{'hover':'#750778'}}>Let's get to Know Jewelercoin</button>
            </a>
           
            </p>
        </div>
        <div>
          <img className="w-full object-fill" src={Metaverse} alt="/" />
        </div>
       
      </div>
    </div>
  );
};

export default Hero;
