/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ethers } from 'ethers';
import CoinbaseWalletSDK   from "@coinbase/wallet-sdk";
import WalletConnect    from "@walletconnect/web3-provider";
import Web3Modal  from "web3modal";
import swal from 'sweetalert';
import { Audio, Oval, ColorRing } from  'react-loader-spinner'
//import { networks } from '../../../hardhat.config';

// const dotenv  = require('dotenv')

// dotenv.config({
//   path: './.env'
// })

//BEP20 PRESALE TESTNET https://testnet.bscscan.com/address/0x76449885F6af0Bc4f9aCC1C2dc0e4B07d4AabaE4#code
//BEP20 JEWER TOKEN TESTNET https://testnet.bscscan.com/address/0x6344f31C161AFBa9B6AfA572fBbe189eB924cf47#code
//import bep20testnetPresaleABI from '../artifacts/abis/bep20Presale.json';//../artifacts/abis/bep20Presale.json
const bep20UsdtContractABI = require('../artifacts/abis/bep20USDTABI.json');
const bep20BusdContractABI = require('../artifacts/abis/bep20BUSDABI.json');
const jewerBEP20MainnetPresaleABI = require('../artifacts/abis/jewerBEP20MainnetPresale.json')
const jewerERC20MainnetPresaleABI = require('../artifacts/abis/jewerERC20MainnetPresale.json')

const erc20USDTContractABI = require('../artifacts/abis/erc20USDTMainnet.json')
const erc20BUSDContractABI = require('../artifacts/abis/erc20BUSDMainnet.json')

const INFURA_RPC = '3f1ffc6f67744943bef45bb4ca1e7314' //https://mainnet.infura.io/v3/

export const PresaleContext = React.createContext();


const showLoader = async() =>{
  return(
    <>
       <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#b8c480', '#B2A3B5', '#F4442E', '#51E5FF', '#429EA6']}
      />

    </>
  )
 
}

export const PresaleContextProvider = ({ children }) => { 
  
    const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
    const BUSD_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    const JewelBep20MainnetPresaleAddress = '0x7238111B514870941fA8a737Fff0AC7750448FA4'
    const JewelERC20MainnetPresaleAddress = '0x7238111B514870941fA8a737Fff0AC7750448FA4'

    const erc20BUSDContractAddress = '0x4Fabb145d64652a948d72533023f6E7A623C7C53'
    const erc20USDTContractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'

    //const [web3Signer, setSigner] = useState();
    const [connectedAccount, setConnectedAccount] = useState()
    const [presaleDuration, setPresaleDuration] = useState()
    const [library, setLibrary] = useState()
    const [network, setNetwork] = useState()
    const [chainId, setChainId] = useState()
    const [account, setAccount] = useState()
    const [connectionlibrary, setConnectionlibrary] = useState()
    const [isConnected, setConnected] = useState(false)
    const [provider, setProvider] = useState()

    //CONTRACT SPECIFIC
    const [bep20PresaleContract, setBep20PresaleContract] = useState()
    const [isPresaleActive, setIsPresaleActive] = useState()
    const [isPresalePaused, setIsPresalePaused] = useState()
    const [isPresaleClaimsOpen,setIsPresaleClaimsOpen] = useState()
    const [pricePerToken, setPricePerToken] = useState(0)
    const [isAnActiveInvestor, setIsAnActiveInvestor] = useState()
    const [totalTokensBoughtByInvestor, setTotalTokensBoughtByInvestor] = useState(0)
    const [totalBNBInvestedByInvestor, setTotalBNBInvestedByInvestor] = useState(0)
    const [totalTokensClaimedByInvestor, setTotalTokensClaimedByInvestor] = useState(0)
    const [totalTokensToBeSold, setTotalTokensToBeSold] = useState(0)
    const [minPurchaseInWei, setMinPurchaseInWei] = useState(0)
    const [maxPurchaseInWei, setMaxPurchaseInWei] = useState(0)
    const [tokenLockPeriod, setTokenLockPeriod] = useState(0)
    const [totalTokensClaimed, setTotalTokensClaimed] = useState(0)
    const [totalTokensPurchased, setTotalTokensPurchased] = useState(0)
    const [totalUSDRaised, setTotalUSDRaised] = useState(0)
    const [totalUSDTRaised, setTotalUSDTRaised] = useState(0)
    const [totalInvestors, setTotalInvestors] = useState(0)
    const [totalBNBRaised, setTotalBNBRaised] = useState(0)
    const [isAllowedToClaimTokens, setIsAllowedToClaimTokens] = useState()
    const [totalUSDTContributedByInvestor, setTotalUSDTContributedByInvestor] = useState(0)

    const [usdtContractInstance, setUsdtContract] = useState()
    const [busdContractInstance, setBusdContract] = useState()

        //Stable coin user balances
    const [userUSDTBalance, setUserUSDTBalance] = useState(0)
    const [userBUSDBalance, setUserBUSDBalance] = useState(0)

    const [mainnetBep20PresaleContract, setMainnetBep20PresaleContract] = useState()
    const [mainnetERC20PresaleContract, setMainnetERC20PresaleContract] = useState()

    const [isBEP20PresaleActive, setIsBEP20PresaleActive] = useState()
    const [isERC20PresaleActive, setIsERC20PresaleActive] = useState()

    const [totalBEP20BNBRaised, setTotalBEP20BNBRaised] = useState()
    const [totalERC20BNBRaised, setTotalERC20BNBRaised] = useState()

    const [isBE20PresalePaused, setIsBE20PresalePaused] = useState()
    const [isERC20PresalePaused, setIsERC20PresalePaused] = useState()

    const [totalInvestorsBEP20, setTotalInvestorsBEP20] = useState()
    const [totalInvestorsERC20, setTotalInvestorsERC20] = useState()

    const [totalUSDRaisedBEP20, setTotalUSDRaisedBEP20] = useState()
    const [totalUSDRaisedERC20, setTotalUSDRaisedERC20] = useState()

    const [totalUSDTRaisedBEP20, setTotalUSDTRaisedBEP20] = useState()
    const [totalUSDTRaisedERC20, setTotalUSDTRaisedERC20] = useState()

    const [totalTokensPurchasedBEP20, setTotalTokensPurchasedBEP20] = useState()
    const [totalTokensPurchasedERC20, setTotalTokensPurchasedERC20] = useState()

    const [totalTokensClaimedBEP20,setTotalTokensClaimedBEP20] = useState()
    const [totalTokensClaimedERC20,setTotalTokensClaimedERC20] = useState()

    const [isPresaleClaimsOpenBEP20, setIsPresaleClaimsOpenBEP20] = useState()
    const [isPresaleClaimsOpenERC20, setIsPresaleClaimsOpenERC20] = useState()

    const [isAnActiveInvestorBEP20, setIsAnActiveInvestorBEP20] = useState()
    const [isAnActiveInvestorERC20, setIsAnActiveInvestorERC20] = useState()

    const [totalTokensBoughtByInvestorBEP20, setTotalTokensBoughtByInvestorBEP20] = useState()
    const [totalTokensBoughtByInvestorERC20, setTotalTokensBoughtByInvestorERC20] = useState()

    const [totalBNBInvestedByInvestorERC20, setTotalBNBInvestedByInvestorERC20] = useState()
    const [totalBNBInvestedByInvestorBEP20, setTotalBNBInvestedByInvestorBEP20] = useState()

    const [totalTokensClaimedByInvestorBEP20, setTotalTokensClaimedByInvestorBEP20] = useState()
    const [totalTokensClaimedByInvestorERC20, setTotalTokensClaimedByInvestorERC20] = useState()

    const [totalUSDTContributedByInvestorBEP20, setTotalUSDTContributedByInvestorBEP20] = useState()
    const [totalUSDTContributedByInvestorERC20, setTotalUSDTContributedByInvestorERC20] = useState()

    const [isAllowedToClaimTokensBEP20, setIsAllowedToClaimTokensBEP20] = useState()
    const [isAllowedToClaimTokensERC20, setIsAllowedToClaimTokensERC20] = useState()

    const [eRC20USDTContractInstance, setERC20USDTContractInstance] = useState()
    const [eRC20BUSDContractInstance, setERC20BUSDContractInstance] = useState()


    const webModalConnection = useRef()

    const showPageLoader = async() => {
      return (
      <>
      <div >
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
      </div>
    </>
      )
    }

    const truncateAddress = (address) => {
      if (!address) return "";
      const match = address.match(
        /^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
      );
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
    };
    
    const toHex = (num) => {
      const val = Number(num);
      return "0x" + val.toString(16);
    };

    const getSignerOrProvider = async() =>{
      const connection = await webModalConnection.current.connect();
      setConnectionlibrary(connection)
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner()
      const accounts = provider.listAccounts();
      const network_connection = provider.getNetwork()
      //console.log(await accounts)
      setLibrary(provider)
      setNetwork(network_connection)
      setChainId(await network_connection.chainId);
      localStorage.setItem('isWalletConnected', true)
      if (accounts){
        setConnectedAccount(accounts[0])
        setAccount(accounts[0]);
        setConnected(true);
      }
      return {
        'signer': signer,
        'provider': provider
      }
    }

    const providerOptions = {
        coinbasewallet: {
          package: CoinbaseWalletSDK, 
          options: {
            appName: "Jewelercoin Presale",
            infuraId: INFURA_RPC,
            chainId: toHex(56),
          }
        },
        walletconnect: {
          package: WalletConnect,
          options: {
            infuraId: INFURA_RPC,
          }
        }
    }

    const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
        //network: 'mainnet' ,
        disableInjectedProvider: false,
        theme: 'dark',
        accentColor: 'blue',
        ethereum: {
          appName: 'web3Modal'
        }
      });

    const connectWallet = async() =>{
        try {
          const connection = await getSignerOrProvider()
          const provider = connection.provider
          const signer = connection.signer
          const accounts = await provider.listAccounts();
          const network = await provider.getNetwork();
          setProvider(connection.provider)
          setLibrary(connection.provider)

          localStorage.setItem('isWalletConnected', true)
          if (accounts){
            setConnectedAccount(accounts[0])
          } 
          setChainId(network.chainId);
          //console.log(network.chainId)
          setAccount(accounts[0]);
          setConnected(true);
          
          if(provider){
            const mainnetBEP20Presale = new ethers.Contract(JewelBep20MainnetPresaleAddress, jewerBEP20MainnetPresaleABI, signer)
            setMainnetBep20PresaleContract(mainnetBEP20Presale)

            const mainnetERC20Presale = new ethers.Contract(JewelERC20MainnetPresaleAddress, jewerERC20MainnetPresaleABI, signer)
            setMainnetERC20PresaleContract(mainnetERC20Presale)
            //console.log(mainnetERC20Presale)
            const usdtContract = new ethers.Contract(USDT_ADDRESS, bep20UsdtContractABI, signer);
            setUsdtContract(usdtContract)

            const _busdContractInstance = new ethers.Contract(BUSD_ADDRESS, bep20BusdContractABI, signer);
            setBusdContract(_busdContractInstance)

            const erc20USDTContractInstance = new ethers.Contract(erc20USDTContractAddress,erc20USDTContractABI , signer);
            setERC20USDTContractInstance(erc20USDTContractInstance)

            const erc20BUSDContractInstance = new ethers.Contract(erc20BUSDContractAddress,erc20BUSDContractABI , signer);
            setERC20BUSDContractInstance(erc20BUSDContractInstance)

            const presaleDuration = await mainnetBEP20Presale.presaleDuration()
            setPresaleDuration(parseInt(presaleDuration))

            const isERC20PresaleActive = await mainnetERC20Presale.isPresaleActive()
            setIsERC20PresaleActive(isERC20PresaleActive)

            const isPresaleActive = await mainnetBEP20Presale.isPresaleActive()
            setIsBEP20PresaleActive(isPresaleActive)

            const isPresalePaused = await mainnetBEP20Presale.isPresalePaused()
            setIsBE20PresalePaused(isPresalePaused)

            const isERC20PresalePaused = await mainnetERC20Presale.isPresalePaused()
            setIsERC20PresalePaused(isERC20PresalePaused)

            const pricePerToken = await mainnetBEP20Presale.pricePerToken()
            const pricePerTokenVal = ethers.utils.formatEther(pricePerToken)
            setPricePerToken(parseFloat(pricePerTokenVal))

            const minPurchaseInWei = await mainnetBEP20Presale.minPurchaseInWei()
            const minPurchaseInWeiVal = ethers.utils.formatEther(minPurchaseInWei)
            setMinPurchaseInWei(parseFloat(minPurchaseInWeiVal))
            
            const maxPurchaseInWei = await mainnetBEP20Presale.maxPurchaseInWei()
            const maxPurchaseInWeiVal = ethers.utils.formatEther(maxPurchaseInWei)
            setMaxPurchaseInWei(parseFloat(maxPurchaseInWeiVal))

            const totalBNBRaisedERC20 = await mainnetERC20Presale.totalWeiRaised()
            const totalWeiRaisedERC20Val = ethers.utils.formatEther(totalBNBRaisedERC20)
            setTotalERC20BNBRaised(totalWeiRaisedERC20Val)

            const totalBNBRaisedBEP20 = await mainnetBEP20Presale.totalWeiRaised()
            const totalWeiRaisedVal = ethers.utils.formatEther(totalBNBRaisedBEP20)
            setTotalBEP20BNBRaised(totalWeiRaisedVal)

            const totalInvestorsERC20 = await mainnetERC20Presale.totalInvestors()
            setTotalInvestorsERC20(parseInt(totalInvestorsERC20))

            const totalInvestorsBEP20 = await mainnetBEP20Presale.totalInvestors()
            setTotalInvestorsBEP20(parseInt(totalInvestorsBEP20))

            const _totalInvestors = parseInt(totalInvestorsBEP20) + parseInt(totalInvestorsERC20)
            setTotalInvestors(parseInt(_totalInvestors))

            const isAnActiveInvestorERC20 = await mainnetERC20Presale.isAnActiveInvestor(accounts[0])
            setIsAnActiveInvestorERC20(isAnActiveInvestorERC20)
            
            const _totalUSDTRaisedBEP20 = await mainnetBEP20Presale.totalUSDTRaised()
            const totalUSDTRaisedBEP20Val = ethers.utils.formatEther(_totalUSDTRaisedBEP20)
            setTotalUSDTRaisedBEP20(totalUSDTRaisedBEP20Val)

            const _totalUSDTRaisedERC20 = await mainnetERC20Presale.totalUSDTRaised()
            const totalUSDTRaisedERC20Val = ethers.utils.formatEther(_totalUSDTRaisedERC20)
            setTotalUSDTRaisedERC20(totalUSDTRaisedERC20Val)
                        //const totalUSDTRaised = setTotalUSDTRaised
            const totalUSDTRaised = parseFloat(totalUSDTRaisedBEP20Val) + parseFloat(totalUSDTRaisedERC20Val)
            setTotalUSDTRaised(totalUSDTRaised)

            //console.log('Total USDT BEP20', totalUSDTRaisedBEP20Val)

            const totalUSDRaisedERC20 = await mainnetERC20Presale.totalUSDRaised()
            const totalUSDRaisedERC20Val = ethers.utils.formatEther(totalUSDRaisedERC20)
            setTotalUSDRaisedERC20(totalUSDRaisedERC20Val)
            
            const userUSDTBalance = await usdtContract.balanceOf(accounts[0]);
            const userBUSDBalance = await _busdContractInstance.balanceOf(accounts[0]);
            //console.log(ethers.utils.formatEther(userBUSDBalance))
            const usdtBEP20Balance = ethers.utils.formatEther(userUSDTBalance)
            const busBEP20Balnce = ethers.utils.formatEther(userBUSDBalance)
            setUserUSDTBalance(usdtBEP20Balance)
            setUserBUSDBalance(busBEP20Balnce)
 
            //console.log(usdtBEP20Balance)

            const totalUSDRaisedBEP20 = await mainnetBEP20Presale.totalUSDRaised()
            const totalUSDRaisedBEP20Val = ethers.utils.formatEther(totalUSDRaisedBEP20)
            setTotalUSDRaisedBEP20(totalUSDRaisedBEP20Val)

            const totalUSDRaised = parseFloat(totalUSDRaisedBEP20Val) + parseFloat(totalUSDRaisedERC20Val)
            setTotalUSDRaised(totalUSDRaised)
            
            const totalTokensforPresale = await mainnetBEP20Presale.totalTokensforPresale()
            const totalTokensforPresaleVal = ethers.utils.formatEther(totalTokensforPresale)
            setTotalTokensToBeSold(parseFloat(totalTokensforPresaleVal))

            const totalBNBRaised = parseFloat(totalWeiRaisedVal) + parseFloat(totalWeiRaisedERC20Val)
            setTotalBNBRaised(totalBNBRaised)

           const totalBNBInvestmentByUserBEP20 = await mainnetBEP20Presale.totalBNBInvestmentByUser(accounts[0])
           const totalBNBInvestmentByUserBEP20Val = ethers.utils.formatEther(totalBNBInvestmentByUserBEP20)
           setTotalBNBInvestedByInvestorBEP20(parseFloat(totalBNBInvestmentByUserBEP20Val))
          
           const totalTokensBoughtByUserBEP20 = await mainnetBEP20Presale.totalTokensBoughtByUser(accounts[0])
           const totalTokensBoughtByUserBEP20Val = ethers.utils.formatEther(totalTokensBoughtByUserBEP20)
           setTotalTokensBoughtByInvestorBEP20(parseFloat(totalTokensBoughtByUserBEP20Val).toFixed(2))

           const totalBNBInvestmentByUserERC20 = await mainnetERC20Presale.totalBNBInvestmentByUser(accounts[0])
           const totalBNBInvestmentByUserERC20Val = ethers.utils.formatEther(totalBNBInvestmentByUserERC20)
           setTotalBNBInvestedByInvestorERC20(parseFloat(totalBNBInvestmentByUserERC20Val).toFixed(2))
          
           const totalBNBInvestmentByUser = parseFloat(totalBNBInvestmentByUserBEP20Val) + parseFloat(totalBNBInvestmentByUserERC20Val)
           setTotalBNBInvestedByInvestor(parseFloat(totalBNBInvestmentByUser))

            const totalTokensBoughtByUserERC20 = await mainnetERC20Presale.totalTokensBoughtByUser(accounts[0])
            const totalTokensBoughtByUserERC20Val = ethers.utils.formatEther(totalTokensBoughtByUserERC20)
            setTotalTokensBoughtByInvestorERC20(parseFloat(totalTokensBoughtByUserERC20Val))
 
            const totalTokensBoughtByInvestor = parseInt(totalTokensBoughtByUserBEP20Val) + parseInt(totalTokensBoughtByUserERC20Val)
            setTotalTokensBoughtByInvestor(parseInt(totalTokensBoughtByInvestor))
            
            //console.log(parseFloat(busBEP20Balnce))
          
            const totalTokensPurchasedBEP20 = await mainnetBEP20Presale.totalTokensPurchased()
            const totalTokensPurchasedBEP20Val = ethers.utils.formatEther(totalTokensPurchasedBEP20)
            setTotalTokensPurchasedBEP20(parseInt(totalTokensPurchasedBEP20Val))

            const totalTokensClaimedByUserBEP20 = await mainnetBEP20Presale.totalTokensClaimedByUser(accounts[0])
            const totalTokensClaimedByUserBEP20Val = ethers.utils.formatEther(totalTokensClaimedByUserBEP20)
            setTotalTokensClaimedByInvestorBEP20(parseFloat(totalTokensClaimedByUserBEP20Val))
            
            const totalTokensPurchasedERC20 = await mainnetERC20Presale.totalTokensPurchased()
            const totalTokensPurchasedERC20Val = ethers.utils.formatEther(totalTokensPurchasedERC20)
            setTotalTokensPurchasedERC20(parseInt(totalTokensPurchasedERC20Val))

            const totalTokensPurchasedOveral = parseInt(totalTokensPurchasedBEP20Val) + parseInt(totalTokensPurchasedERC20Val)
            setTotalTokensPurchased(parseInt(totalTokensPurchasedOveral))

          
            const totalTokensClaimedByUserERC20 = await mainnetERC20Presale.totalTokensClaimedByUser(accounts[0])
            const totalTokensClaimedByUserERC20Val = ethers.utils.formatEther(totalTokensClaimedByUserERC20)
            setTotalTokensClaimedByInvestorERC20(parseFloat(totalTokensClaimedByUserERC20Val))
 
            const totalTokensClaimedByUserOveral = parseFloat(totalTokensClaimedByUserBEP20Val) + parseFloat(totalTokensClaimedByUserERC20Val)
            setTotalTokensClaimedByInvestor(parseFloat(totalTokensClaimedByUserOveral))

            const totalTokensClaimedBEP20 = await mainnetBEP20Presale.totalTokensClaimed()
            const totalTokensClaimedBEP20Val = ethers.utils.formatEther(totalTokensClaimedBEP20)
            setTotalTokensClaimedBEP20(parseFloat(totalTokensClaimedBEP20Val))

            const totalTokensClaimedERC20 = await mainnetERC20Presale.totalTokensClaimed()
            const totalTokensClaimedERC20Val = ethers.utils.formatEther(totalTokensClaimedERC20)
            setTotalTokensClaimedERC20(parseFloat(totalTokensClaimedERC20Val))

            const totalTokensClaimedOveral = parseInt(totalTokensClaimedBEP20Val) + parseInt(totalTokensClaimedERC20Val)
            setTotalTokensClaimed(parseInt(totalTokensClaimedOveral))

            const isPresaleClaimsOpenBEP20 = await mainnetBEP20Presale.isPresaleClaimsOpen()
            setIsPresaleClaimsOpenBEP20(isPresaleClaimsOpenBEP20)

            const isPresaleClaimsOpenERC20 = await mainnetERC20Presale.isPresaleClaimsOpen()
            setIsPresaleClaimsOpenERC20(isPresaleClaimsOpenERC20)
          
           const isAnActiveInvestorBEP20 = await mainnetBEP20Presale.isAnActiveInvestor(accounts[0])
           setIsAnActiveInvestorBEP20(isAnActiveInvestorBEP20)

     

           const totalUSDTContributedByInvestorBEP20 = await mainnetBEP20Presale.totalUSDTContributedByInvestor(accounts[0])
           const totalUSDTContributedByInvestorBEP20Val = ethers.utils.formatEther(totalUSDTContributedByInvestorBEP20)
           setTotalUSDTContributedByInvestorBEP20(parseFloat(totalUSDTContributedByInvestorBEP20Val))

           const totalUSDTContributedByInvestorERC20 = await mainnetERC20Presale.totalUSDTContributedByInvestor(accounts[0])
           const totalUSDTContributedByInvestorERC20Val = ethers.utils.formatEther(totalUSDTContributedByInvestorERC20)
           setTotalUSDTContributedByInvestorERC20(parseFloat(totalUSDTContributedByInvestorERC20Val))

           const totalUSDTInvestedByUserOveral = parseFloat(totalUSDTContributedByInvestorBEP20Val) + parseFloat(totalUSDTContributedByInvestorERC20Val)
           setTotalUSDTContributedByInvestor(parseFloat(totalUSDTInvestedByUserOveral))

           const isAllowedToClaimTokensERC20 = await mainnetERC20Presale.isAllowedToClaimTokens(accounts[0])
           setIsAllowedToClaimTokensERC20(isAllowedToClaimTokensERC20)

           const isAllowedToClaimTokensBEP20 = await mainnetBEP20Presale.isAllowedToClaimTokens(accounts[0])
           setIsAllowedToClaimTokensBEP20(isAllowedToClaimTokensBEP20)

          //  const userUSDTBalance = await usdtContract.balanceOf(accounts[0]);
          //  const userBUSDBalance = await _busdContractInstance.balanceOf(accounts[0]);
          //  //console.log(ethers.utils.formatEther(userBUSDBalance))
          //  const usdtBEP20Balance = ethers.utils.formatEther(userUSDTBalance)
          //  const busBEP20Balnce = ethers.utils.formatEther(userBUSDBalance)
          //  setUserUSDTBalance(usdtBEP20Balance)

          //  setUserBUSDBalance(busBEP20Balnce)
          }

          localStorage.setItem('isWalletConnected', true)
        }catch(error){
          
        }
    }

    const loadBlockchainData = async() => {
      const mainnet_provider_url = 'https://bsc-dataseed.binance.org/'
      //const testnet_provider_url = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      const eth_mainnet_rpc = INFURA_RPC

      const bscProvider = new ethers.providers.JsonRpcProvider(mainnet_provider_url);
      //const signer_ = bscProvider.getSigner();

      const infuraEthProvider = new ethers.providers.InfuraProvider('mainnet', eth_mainnet_rpc)

      const mainnetBEP20Presale = new ethers.Contract(JewelBep20MainnetPresaleAddress, jewerBEP20MainnetPresaleABI, bscProvider)
      setMainnetBep20PresaleContract(mainnetBEP20Presale)

      const mainnetERC20Presale = new ethers.Contract(JewelERC20MainnetPresaleAddress, jewerERC20MainnetPresaleABI, infuraEthProvider)
      setMainnetERC20PresaleContract(mainnetERC20Presale)
      
      const presaleDuration = await mainnetBEP20Presale.presaleDuration()
      setPresaleDuration(parseInt(presaleDuration))

      const isERC20PresaleActive = await mainnetERC20Presale.isPresaleActive()
      setIsERC20PresaleActive(isERC20PresaleActive)

      const isPresaleActive = await mainnetBEP20Presale.isPresaleActive()
      setIsBEP20PresaleActive(isPresaleActive)

      const isPresalePaused = await mainnetBEP20Presale.isPresalePaused()
      setIsBE20PresalePaused(isPresalePaused)

      const isERC20PresalePaused = await mainnetERC20Presale.isPresalePaused()
      setIsERC20PresalePaused(isERC20PresalePaused)

      const pricePerToken = await mainnetBEP20Presale.pricePerToken()
      const pricePerTokenVal = ethers.utils.formatEther(pricePerToken)
      setPricePerToken(parseFloat(pricePerTokenVal))

      const minPurchaseInWei = await mainnetBEP20Presale.minPurchaseInWei()
      const minPurchaseInWeiVal = ethers.utils.formatEther(minPurchaseInWei)
      setMinPurchaseInWei(parseFloat(minPurchaseInWeiVal))
      
      const maxPurchaseInWei = await mainnetBEP20Presale.maxPurchaseInWei()
      const maxPurchaseInWeiVal = ethers.utils.formatEther(maxPurchaseInWei)
      setMaxPurchaseInWei(parseFloat(maxPurchaseInWeiVal))

      const totalBNBRaisedERC20 = await mainnetERC20Presale.totalWeiRaised()
      const totalWeiRaisedERC20Val = ethers.utils.formatEther(totalBNBRaisedERC20)
      setTotalERC20BNBRaised(totalWeiRaisedERC20Val)

      const totalBNBRaisedBEP20 = await mainnetBEP20Presale.totalWeiRaised()
      const totalWeiRaisedVal = ethers.utils.formatEther(totalBNBRaisedBEP20)
      setTotalBEP20BNBRaised(totalWeiRaisedVal)

      const totalInvestorsERC20 = await mainnetERC20Presale.totalInvestors()
      setTotalInvestorsERC20(parseInt(totalInvestorsERC20))

      const totalInvestorsBEP20 = await mainnetBEP20Presale.totalInvestors()
      setTotalInvestorsBEP20(parseInt(totalInvestorsBEP20))

      const _totalInvestors = parseInt(totalInvestorsBEP20) + parseInt(totalInvestorsERC20)
      setTotalInvestors(parseInt(_totalInvestors))

      
      const _totalUSDTRaisedBEP20 = await mainnetBEP20Presale.totalUSDTRaised()
      const totalUSDTRaisedBEP20Val = ethers.utils.formatEther(_totalUSDTRaisedBEP20)
      setTotalUSDTRaisedBEP20(totalUSDTRaisedBEP20Val)

      const _totalUSDTRaisedERC20 = await mainnetERC20Presale.totalUSDTRaised()
      const totalUSDTRaisedERC20Val = ethers.utils.formatEther(_totalUSDTRaisedERC20)
      setTotalUSDTRaisedERC20(totalUSDTRaisedERC20Val)

      const totalUSDTRaised = parseFloat(totalUSDTRaisedBEP20Val) + parseFloat(totalUSDTRaisedERC20Val)
      setTotalUSDTRaised(totalUSDTRaised)

      const totalUSDRaisedERC20 = await mainnetERC20Presale.totalUSDRaised()
      const totalUSDRaisedERC20Val = ethers.utils.formatEther(totalUSDRaisedERC20)
      setTotalUSDRaisedERC20(totalUSDRaisedERC20Val)
      
      const totalUSDRaisedBEP20 = await mainnetBEP20Presale.totalUSDRaised()
      const totalUSDRaisedBEP20Val = ethers.utils.formatEther(totalUSDRaisedBEP20)
      setTotalUSDRaisedBEP20(totalUSDRaisedBEP20Val)

      const totalUSDRaised = parseFloat(totalUSDRaisedBEP20Val) + parseFloat(totalUSDRaisedERC20Val)
      setTotalUSDRaised(totalUSDRaised)
      
      const totalTokensforPresale = await mainnetBEP20Presale.totalTokensforPresale()
      const totalTokensforPresaleVal = ethers.utils.formatEther(totalTokensforPresale)
      setTotalTokensToBeSold(parseFloat(totalTokensforPresaleVal))

      const totalBNBRaised = parseFloat(totalWeiRaisedVal) + parseFloat(totalWeiRaisedERC20Val)
      setTotalBNBRaised(totalBNBRaised)

    
      const totalTokensPurchasedBEP20 = await mainnetBEP20Presale.totalTokensPurchased()
      const totalTokensPurchasedBEP20Val = ethers.utils.formatEther(totalTokensPurchasedBEP20)
      setTotalTokensPurchasedBEP20(parseInt(totalTokensPurchasedBEP20Val))

      const totalTokensPurchasedERC20 = await mainnetERC20Presale.totalTokensPurchased()
      const totalTokensPurchasedERC20Val = ethers.utils.formatEther(totalTokensPurchasedERC20)
      setTotalTokensPurchasedERC20(parseInt(totalTokensPurchasedERC20Val))

      const totalTokensPurchasedOveral = parseInt(totalTokensPurchasedBEP20Val) + parseInt(totalTokensPurchasedERC20Val)
      setTotalTokensPurchased(parseInt(totalTokensPurchasedOveral))

      const totalTokensClaimedBEP20 = await mainnetBEP20Presale.totalTokensClaimed()
      const totalTokensClaimedBEP20Val = ethers.utils.formatEther(totalTokensClaimedBEP20)
      setTotalTokensClaimedBEP20(parseFloat(totalTokensClaimedBEP20Val))

      const totalTokensClaimedERC20 = await mainnetERC20Presale.totalTokensClaimed()
      const totalTokensClaimedERC20Val = ethers.utils.formatEther(totalTokensClaimedERC20)
      setTotalTokensClaimedERC20(parseFloat(totalTokensClaimedERC20Val))

      const totalTokensClaimedOveral = parseInt(totalTokensClaimedBEP20Val) + parseInt(totalTokensClaimedERC20Val)
      setTotalTokensClaimed(parseInt(totalTokensClaimedOveral))

      const isPresaleClaimsOpenBEP20 = await mainnetBEP20Presale.isPresaleClaimsOpen()
      setIsPresaleClaimsOpenBEP20(isPresaleClaimsOpenBEP20)

      const isPresaleClaimsOpenERC20 = await mainnetERC20Presale.isPresaleClaimsOpen()
      setIsPresaleClaimsOpenERC20(isPresaleClaimsOpenERC20)
    }

    const switchBinanceSmartChainNetwork = async () => {
      try {
        await library.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ 
            chainId: toHex(56),
            
          }] 
        });
        setChainId(toHex(56))
        setConnected(true);
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await library.provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                chainId: toHex(56),
                chainName:'Smart Chain',
                rpcUrls:['https://bsc-dataseed.binance.org/'],
                blockExplorerUrls:['https://bscscan.com'],
                nativeCurrency: { 
                  symbol:'BNB',   
                  decimals: 18
                }        
              }
                
              ]
            });
            //setNetwork(56)
          } catch (error) {
            //setError(error);
            console.error(error);
            window.location.reload()
          }
        }
      }
    }

    const switchToEthereumNetwork = async () => {
      try {
        await library.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ 
            chainId: toHex(1),
            
          }] 
        });
        setChainId(toHex(1))
        setConnected(true);
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await library.provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                chainId: toHex(1),
                chainName:'Ethereum Mainnet',
                rpcUrls:[`https://mainnet.infura.io/v3/${INFURA_RPC}`],
                blockExplorerUrls:['https://etherscan.io'],
                nativeCurrency: { 
                  symbol:'ETH',   
                  decimals: 18
                }        
              }
                
              ]
            });
            //setNetwork(56)
          } catch (error) {
            //setError(error);
            console.error(error);
            window.location.reload()
          }
        }
      }
    }

    const refreshState = () => {
      setConnectedAccount()
      setConnected();
      setChainId();
      setNetwork();
      localStorage.setItem('isWalletConnected', false)
      // setPresaleContract(null)
      // setTokenContract(null)
      // setTokenAirdropContract(null)
      //setAccount("");
      };
      
      const disconnectWallet = useCallback(async () => {
        //deactivate();
        web3Modal.clearCachedProvider();
        refreshState();
        localStorage.setItem('isWalletConnected', false)
      });
    
    const buyTokenWithBNB = async(amount) => {
      try{
        const presaleContractInstance = mainnetBep20PresaleContract
        const txHash = await presaleContractInstance.buyToken({value: amount}); //gasLimit: 600000
        await txHash.wait()
        //console.log(txHash)
        const errorData = Object.entries(txHash);
        let data = errorData.map( ([key, val]) => {
          return `${val}`
        });
        var htmlContent = document.createElement("button");
        var link = `<a href='https://bscscan.com/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link
        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Purchase Request Submitted Successfully!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent,
        
        })
        refreshAccountContributionData(connectedAccount)
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        //setError(error);
        console.log(error);
        window.location.reload()
      }
    }

    const buyTokenWithETH = async(amount) => {
      try{
        const presaleContractInstance = mainnetERC20PresaleContract
        const txHash = await presaleContractInstance.buyToken({value: amount}); //gasLimit: 600000
        await txHash.wait()
        //console.log(txHash)
        const errorData = Object.entries(txHash);
        let data = errorData.map( ([key, val]) => {
          return `${val}`
        });
        var htmlContent = document.createElement("button");
        var link = `<a href='https://etherscan.io/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link
        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Purchase Request Submitted Successfully!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent,
        
        })
        refreshAccountContributionData(connectedAccount)
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        //setError(error);
        console.log(error);
        window.location.reload()
      }
    }

    const approveBEP20USDTStableCoin = async(address, amount) =>{
      try{
        const connectedUsdtContractInstance =  usdtContractInstance 
        //connectedAccount
        const balance = userUSDTBalance;// connectedUsdtContractInstance.balanceOf(connectedAccount);
        //console.log(balance)
        if(balance < amount ){
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error...',
            dangerMode: true,
            text: 'Insufficient USDT Balance!'
          })
        }else{
          const txHash = await connectedUsdtContractInstance.approve(address, amount); //{gasLimit: 600000}
          //showLoader()
          await txHash.wait()
          //setCanShowProgressBar(true)
          //console.log(txHash)
          const errorData = Object.entries(txHash);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          var htmlContent = document.createElement("button");
          var link = `<a href='https://bscscan.com/tx/${data[0]}' target='__blank'>View On Explorer</a>`
          htmlContent.innerHTML = link
          swal({
            icon: "success",
            success: true,
            button: {
              confirm: true,
            },
            title: 'Approval Success!',
            dangerMode: false,
            //text: `View Progress on Explorer: ${data[0]}`,
            content: htmlContent,
          
          })
          //const t = ethers.utils.parseEther(amount)
          executeBEP20StableCoinPurchase(USDT_ADDRESS, amount);
          //setCanShowProgressBar(false)
          refreshAccountContributionData(connectedAccount)
          //setCanShowProgressBar(false)
         
          //console.log(data[0])
          //refreshAccountContributionData(connectedAccount)

          // swal({
          //   icon: "success",
          //   success: true,
          //   button: {
          //     confirm: true,
          //   },
          //   title: 'Approval Success!',
          //   dangerMode: false,
          //   //text: `View Progress on Explorer: ${data[0]}`,
          //   content: htmlContent,
          
          // })
         
          
        }

      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        console.log(error);
        window.location.reload()
      }
    }

    const approveBEP20BUSDStableCoin = async(address, amount) =>{
      try{
        const balance = userBUSDBalance;// connectedUsdtContractInstance.balanceOf(connectedAccount);
        //console.log(balance)
        if(balance < amount ){
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error...',
            dangerMode: true,
            text: 'Insufficient BUSD Balance!'
          })
        }else{
          const connectedBusdContractInstance = busdContractInstance 
          const txHash = await connectedBusdContractInstance.approve(address, amount); //{gasLimit: 600000}
          await txHash.wait()
          const errorData = Object.entries(txHash);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          //console.log(data[0])
          
          var htmlContent = document.createElement("button");
          var link = `<a href='https://bscscan.com/tx/${data[0]}' target='__blank'>View On Explorer</a>`
          htmlContent.innerHTML = link
          swal({
            icon: "success",
            success: true,
            button: {
              confirm: true,
            },
            title: 'Approval Success!',
            dangerMode: false,
            //text: `View Progress on Explorer: ${data[0]}`,
            content: htmlContent,
          
          })
          executeBEP20StableCoinPurchase(BUSD_ADDRESS, amount);
          //setCanShowProgressBar(false)
          refreshAccountContributionData(connectedAccount)
        }
        
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        console.log(error);
        window.location.reload()
      }
    }

    const approveERC20USDTStableCoin = async(address, amount) =>{
      try{
        const connectedUsdtContractInstance =  eRC20USDTContractInstance 
        //connectedAccount
        const balance = userUSDTBalance;// connectedUsdtContractInstance.balanceOf(connectedAccount);
        //console.log(balance)
        if(balance < amount ){
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error...',
            dangerMode: true,
            text: 'Insufficient USDT Balance!'
          })
        }else{
          const txHash = await connectedUsdtContractInstance.approve(address, amount); //{gasLimit: 600000}
          //showLoader()
          await txHash.wait()
          //setCanShowProgressBar(true)
          //console.log(txHash)
          const errorData = Object.entries(txHash);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          var htmlContent = document.createElement("button");
          var link = `<a href='https://etherscan.io/tx/${data[0]}' target='__blank'>View On Explorer</a>`
          htmlContent.innerHTML = link
          swal({
            icon: "success",
            success: true,
            button: {
              confirm: true,
            },
            title: 'Approval Success!',
            dangerMode: false,
            //text: `View Progress on Explorer: ${data[0]}`,
            content: htmlContent,
          
          })
          //const t = ethers.utils.parseEther(amount)
          executeERC20StableCoinPurchase(erc20USDTContractAddress, amount);
          //setCanShowProgressBar(false)
          refreshAccountContributionData(connectedAccount)
          //setCanShowProgressBar(false)
         
          //console.log(data[0])
          //refreshAccountContributionData(connectedAccount)

          // swal({
          //   icon: "success",
          //   success: true,
          //   button: {
          //     confirm: true,
          //   },
          //   title: 'Approval Success!',
          //   dangerMode: false,
          //   //text: `View Progress on Explorer: ${data[0]}`,
          //   content: htmlContent,
          
          // })
         
          
        }

      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        console.log(error);
        window.location.reload()
      }
    }

    const approveERC20BUSDStableCoin = async(address, amount) =>{
      try{
        const balance = userBUSDBalance;// connectedUsdtContractInstance.balanceOf(connectedAccount);
        //console.log(balance)
        if(balance < amount ){
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error...',
            dangerMode: true,
            text: 'Insufficient BUSD Balance!'
          })
        }else{
          const connectedBusdContractInstance = eRC20BUSDContractInstance 
          const txHash = await connectedBusdContractInstance.approve(address, amount); //{gasLimit: 600000}
          await txHash.wait()
          const errorData = Object.entries(txHash);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          //console.log(data[0])
          
          var htmlContent = document.createElement("button");
          var link = `<a href='https://etherscan.io/tx/${data[0]}' target='__blank'>View On Explorer</a>`
          htmlContent.innerHTML = link
          swal({
            icon: "success",
            success: true,
            button: {
              confirm: true,
            },
            title: 'Approval Success!',
            dangerMode: false,
            //text: `View Progress on Explorer: ${data[0]}`,
            content: htmlContent,
          
          })
          executeERC20StableCoinPurchase(erc20BUSDContractAddress, amount);
          //setCanShowProgressBar(false)
          refreshAccountContributionData(connectedAccount)
        }
        
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        console.log(error);
        window.location.reload()
      }
    }


    const executeBEP20StableCoinPurchase = async(coinAddress, amount) =>{
      try{
        const txHash = await mainnetBep20PresaleContract.buyWithStablecoin(coinAddress, amount); //gasLimit: 300000
        await txHash.wait()
        const errorData = Object.entries(txHash);
        let data = errorData.map( ([key, val]) => {
          return `${val}`
        });
    
        var htmlContent = document.createElement("button");
        var link = `<a href='https://bscscan.com/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link
        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Purchase Request Submitted Successfully!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent,
        
        })
        refreshAccountContributionData(connectedAccount)
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        
        console.log(error);
        window.location.reload()
      }
    }

    const executeERC20StableCoinPurchase = async(coinAddress, amount) =>{
      try{
        const txHash = await mainnetERC20PresaleContract.buyWithStablecoin(coinAddress, amount); //gasLimit: 300000
        await txHash.wait()
        const errorData = Object.entries(txHash);
        let data = errorData.map( ([key, val]) => {
          return `${val}`
        });
    
        var htmlContent = document.createElement("button");
        var link = `<a href='https://etherscan.io/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link
        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Purchase Request Submitted Successfully!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent,
        
        })
        refreshAccountContributionData(connectedAccount)
      }catch(error){
        const errorData = Object.entries(error);
          let data = errorData.map( ([key, val]) => {
            return `${val}`
          });
          
          swal({
            icon: "danger",
            success: false,
            button: {
              confirm: true,
            },
            title: 'Error: ',
            dangerMode: true,
            text: `${data[0]}`
          })
        
        console.log(error);
        window.location.reload()
      }
    }

    const claimFromERC20Contribution = async() => {
      try{
        const presaleContractInstance = mainnetERC20PresaleContract
        var htmlContent = document.createElement("button");
        
        const tx = await presaleContractInstance.claimToken()
        await tx.wait()
        const errorData = Object.entries(tx);
        let data = errorData.map( ([key, val]) => {
            return `${val}`
        });
        
        var link = `<a href='https://etherscan.io/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link

        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Success!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent
        })

        refreshAccountContributionData(connectedAccount)
        //const tx = await presaleContractInstance.claimContribution().then(async (response) => {
      }catch(error){
        const errorData = Object.entries(error);
        let data = errorData.map( ([key, val]) => {
            return `${val}`
        });
        swal({
          icon: "danger",
          success: false,
          button: {
            confirm: true,
          },
          title: 'Error Sending Transaction!',
          dangerMode: true,
          //text: `View Progress on Explorer: ${data[0]}`,
          text: `${data[0]}`
        })
        console.error(error);
        window.location.reload()
      }
    }

    const claimFromBEP20Contribution = async() => {
      try{
        const presaleContractInstance = mainnetBep20PresaleContract
        var htmlContent = document.createElement("button");
        
        const tx = await presaleContractInstance.claimToken()
        await tx.wait()
        const errorData = Object.entries(tx);
        let data = errorData.map( ([key, val]) => {
            return `${val}`
        });
        
        var link = `<a href='https://bscscan.com/tx/${data[0]}' target='__blank'>View On Explorer</a>`
        htmlContent.innerHTML = link

        swal({
          icon: "success",
          success: true,
          button: {
            confirm: true,
          },
          title: 'Success!',
          dangerMode: false,
          //text: `View Progress on Explorer: ${data[0]}`,
          content: htmlContent
        })

        refreshAccountContributionData(connectedAccount)
        //const tx = await presaleContractInstance.claimContribution().then(async (response) => {
      }catch(error){
        const errorData = Object.entries(error);
        let data = errorData.map( ([key, val]) => {
            return `${val}`
        });
        swal({
          icon: "danger",
          success: false,
          button: {
            confirm: true,
          },
          title: 'Error Sending Transaction!',
          dangerMode: true,
          //text: `View Progress on Explorer: ${data[0]}`,
          text: `${data[0]}`
        })
        console.error(error);
        window.location.reload()
      }
    }

    const disconnect = async() => {
      web3Modal.clearCachedProvider();
      refreshState()
    }

    const connectWalletOnPageLoad = async () => {
      // if(webModalConnection.current.cacheProvider){
      //   connectWallet();
      // }
      // if (localStorage?.getItem('isWalletConnected') === 'true') {
      //   try {
      //     connectWallet();
      // }
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          connectWallet();
    
        } catch (error) {
         // setError(error);
          console.error(error);
        }
      }
    }

  const refreshAccountContributionData = async(account) => {
    if(library !== undefined){
      const mainnetBEP20Presale = mainnetBep20PresaleContract;
      const mainnetERC20Presale = mainnetERC20PresaleContract;
      const usdtContract = usdtContractInstance;
      const _busdContractInstance = busdContractInstance;
      //setBep20PresaleContract(testnetBEP20Presale)
      
      const presaleDuration = await mainnetBEP20Presale.presaleDuration()
      setPresaleDuration(parseInt(presaleDuration))

      const isERC20PresaleActive = await mainnetERC20Presale.isPresaleActive()
      setIsERC20PresaleActive(isERC20PresaleActive)

      const isPresaleActive = await mainnetBEP20Presale.isPresaleActive()
      setIsBEP20PresaleActive(isPresaleActive)

      const isPresalePaused = await mainnetBEP20Presale.isPresalePaused()
      setIsBE20PresalePaused(isPresalePaused)

      const isERC20PresalePaused = await mainnetERC20Presale.isPresalePaused()
      setIsERC20PresalePaused(isERC20PresalePaused)

      const pricePerToken = await mainnetBEP20Presale.pricePerToken()
      const pricePerTokenVal = ethers.utils.formatEther(pricePerToken)
      setPricePerToken(parseFloat(pricePerTokenVal))

      const minPurchaseInWei = await mainnetBEP20Presale.minPurchaseInWei()
      const minPurchaseInWeiVal = ethers.utils.formatEther(minPurchaseInWei)
      setMinPurchaseInWei(parseFloat(minPurchaseInWeiVal))
      
      const maxPurchaseInWei = await mainnetBEP20Presale.maxPurchaseInWei()
      const maxPurchaseInWeiVal = ethers.utils.formatEther(maxPurchaseInWei)
      setMaxPurchaseInWei(parseFloat(maxPurchaseInWeiVal))

      const totalBNBRaisedERC20 = await mainnetERC20Presale.totalWeiRaised()
      const totalWeiRaisedERC20Val = ethers.utils.formatEther(totalBNBRaisedERC20)
      setTotalERC20BNBRaised(totalWeiRaisedERC20Val)

      const totalBNBRaisedBEP20 = await mainnetBEP20Presale.totalWeiRaised()
      const totalWeiRaisedVal = ethers.utils.formatEther(totalBNBRaisedBEP20)
      setTotalBEP20BNBRaised(totalWeiRaisedVal)

      const totalInvestorsERC20 = await mainnetERC20Presale.totalInvestors()
      setTotalInvestorsERC20(parseInt(totalInvestorsERC20))

      const totalInvestorsBEP20 = await mainnetBEP20Presale.totalInvestors()
      setTotalInvestorsBEP20(parseInt(totalInvestorsBEP20))

      const _totalInvestors = parseInt(totalInvestorsBEP20) + parseInt(totalInvestorsERC20)
      setTotalInvestors(parseInt(_totalInvestors))

      const isAnActiveInvestorERC20 = await mainnetERC20Presale.isAnActiveInvestor(account)
      setIsAnActiveInvestorERC20(isAnActiveInvestorERC20)
      
      const _totalUSDTRaisedBEP20 = await mainnetBEP20Presale.totalUSDTRaised()
      const totalUSDTRaisedBEP20Val = ethers.utils.formatEther(_totalUSDTRaisedBEP20)
      setTotalUSDTRaisedBEP20(totalUSDTRaisedBEP20Val)

      const _totalUSDTRaisedERC20 = await mainnetERC20Presale.totalUSDTRaised()
      const totalUSDTRaisedERC20Val = ethers.utils.formatEther(_totalUSDTRaisedERC20)
      setTotalUSDTRaisedERC20(totalUSDTRaisedERC20Val)
                  //const totalUSDTRaised = setTotalUSDTRaised
      const totalUSDTRaised = parseFloat(totalUSDTRaisedBEP20Val) + parseFloat(totalUSDTRaisedERC20Val)
      setTotalUSDTRaised(totalUSDTRaised)

      //console.log('Total USDT BEP20', totalUSDTRaisedBEP20Val)

      const totalUSDRaisedERC20 = await mainnetERC20Presale.totalUSDRaised()
      const totalUSDRaisedERC20Val = ethers.utils.formatEther(totalUSDRaisedERC20)
      setTotalUSDRaisedERC20(totalUSDRaisedERC20Val)
      
      const totalUSDRaisedBEP20 = await mainnetBEP20Presale.totalUSDRaised()
      const totalUSDRaisedBEP20Val = ethers.utils.formatEther(totalUSDRaisedBEP20)
      setTotalUSDRaisedBEP20(totalUSDRaisedBEP20Val)

      const totalUSDRaised = parseFloat(totalUSDRaisedBEP20Val) + parseFloat(totalUSDRaisedERC20Val)
      setTotalUSDRaised(totalUSDRaised)

      const userUSDTBalance = await usdtContract.balanceOf(account);
      const userBUSDBalance = await _busdContractInstance.balanceOf(account);
      //console.log(ethers.utils.formatEther(userBUSDBalance))
      const usdtBEP20Balance = ethers.utils.formatEther(userUSDTBalance)
      const busBEP20Balnce = ethers.utils.formatEther(userBUSDBalance)
      setUserUSDTBalance(parseFloat(usdtBEP20Balance))
 
      setUserBUSDBalance(parseFloat(busBEP20Balnce))

      const totalTokensforPresale = await mainnetBEP20Presale.totalTokensforPresale()
      const totalTokensforPresaleVal = ethers.utils.formatEther(totalTokensforPresale)
      setTotalTokensToBeSold(parseFloat(totalTokensforPresaleVal))

      const totalBNBRaised = parseFloat(totalWeiRaisedVal) + parseFloat(totalWeiRaisedERC20Val)
      setTotalBNBRaised(totalBNBRaised)

     const totalBNBInvestmentByUserBEP20 = await mainnetBEP20Presale.totalBNBInvestmentByUser(account)
     const totalBNBInvestmentByUserBEP20Val = ethers.utils.formatEther(totalBNBInvestmentByUserBEP20)
     setTotalBNBInvestedByInvestorBEP20(parseFloat(totalBNBInvestmentByUserBEP20Val))

     const totalBNBInvestmentByUserERC20 = await mainnetERC20Presale.totalBNBInvestmentByUser(account)
     const totalBNBInvestmentByUserERC20Val = ethers.utils.formatEther(totalBNBInvestmentByUserERC20)
     setTotalBNBInvestedByInvestorERC20(parseFloat(totalBNBInvestmentByUserERC20Val))
    
     const totalBNBInvestmentByUser = parseFloat(totalBNBInvestmentByUserBEP20Val) + parseFloat(totalBNBInvestmentByUserERC20Val)
     setTotalBNBInvestedByInvestor(parseFloat(totalBNBInvestmentByUser))

      const totalTokensBoughtByUserBEP20 = await mainnetBEP20Presale.totalTokensBoughtByUser(account)
      const totalTokensBoughtByUserBEP20Val = ethers.utils.formatEther(totalTokensBoughtByUserBEP20)
      setTotalTokensBoughtByInvestorBEP20(parseFloat(totalTokensBoughtByUserBEP20Val))
      
      const totalTokensBoughtByUserERC20 = await mainnetERC20Presale.totalTokensBoughtByUser(account)
      const totalTokensBoughtByUserERC20Val = ethers.utils.formatEther(totalTokensBoughtByUserERC20)
      setTotalTokensBoughtByInvestorERC20(parseFloat(totalTokensBoughtByUserERC20Val))

      const totalTokensBoughtByInvestor = parseInt(totalTokensBoughtByUserBEP20Val) + parseInt(totalTokensBoughtByUserERC20Val)
      setTotalTokensBoughtByInvestor(parseInt(totalTokensBoughtByInvestor))
      
      //console.log(parseFloat(busBEP20Balnce))
    
      const totalTokensPurchasedBEP20 = await mainnetBEP20Presale.totalTokensPurchased()
      const totalTokensPurchasedBEP20Val = ethers.utils.formatEther(totalTokensPurchasedBEP20)
      setTotalTokensPurchasedBEP20(parseInt(totalTokensPurchasedBEP20Val))

      const totalTokensPurchasedERC20 = await mainnetERC20Presale.totalTokensPurchased()
      const totalTokensPurchasedERC20Val = ethers.utils.formatEther(totalTokensPurchasedERC20)
      setTotalTokensPurchasedERC20(parseInt(totalTokensPurchasedERC20Val))

      const totalTokensPurchasedOveral = parseInt(totalTokensPurchasedBEP20Val) + parseInt(totalTokensPurchasedERC20Val)
      setTotalTokensPurchased(parseInt(totalTokensPurchasedOveral))

      const totalTokensClaimedBEP20 = await mainnetBEP20Presale.totalTokensClaimed()
      const totalTokensClaimedBEP20Val = ethers.utils.formatEther(totalTokensClaimedBEP20)
      setTotalTokensClaimedBEP20(parseFloat(totalTokensClaimedBEP20Val))

      const totalTokensClaimedERC20 = await mainnetERC20Presale.totalTokensClaimed()
      const totalTokensClaimedERC20Val = ethers.utils.formatEther(totalTokensClaimedERC20)
      setTotalTokensClaimedERC20(parseFloat(totalTokensClaimedERC20Val))

      const totalTokensClaimedOveral = parseInt(totalTokensClaimedBEP20Val) + parseInt(totalTokensClaimedERC20Val)
      setTotalTokensClaimed(parseInt(totalTokensClaimedOveral))

      const isPresaleClaimsOpenBEP20 = await mainnetBEP20Presale.isPresaleClaimsOpen()
      setIsPresaleClaimsOpenBEP20(isPresaleClaimsOpenBEP20)

      const isPresaleClaimsOpenERC20 = await mainnetERC20Presale.isPresaleClaimsOpen()
      setIsPresaleClaimsOpenERC20(isPresaleClaimsOpenERC20)
    
     const isAnActiveInvestorBEP20 = await mainnetBEP20Presale.isAnActiveInvestor(account)
     setIsAnActiveInvestorBEP20(isAnActiveInvestorBEP20)

     const totalTokensClaimedByUserBEP20 = await mainnetBEP20Presale.totalTokensClaimedByUser(account)
     const totalTokensClaimedByUserBEP20Val = ethers.utils.formatEther(totalTokensClaimedByUserBEP20)
     setTotalTokensClaimedByInvestorBEP20(parseFloat(totalTokensClaimedByUserBEP20Val))

     const totalTokensClaimedByUserERC20 = await mainnetERC20Presale.totalTokensClaimedByUser(account)
     const totalTokensClaimedByUserERC20Val = ethers.utils.formatEther(totalTokensClaimedByUserERC20)
     setTotalTokensClaimedByInvestorERC20(parseFloat(totalTokensClaimedByUserERC20Val))

     const totalTokensClaimedByUserOveral = parseFloat(totalTokensClaimedByUserBEP20Val) + parseFloat(totalTokensClaimedByUserERC20Val)
     setTotalTokensClaimedByInvestor(parseFloat(totalTokensClaimedByUserOveral))

     const totalUSDTContributedByInvestorBEP20 = await mainnetBEP20Presale.totalUSDTContributedByInvestor(account)
     const totalUSDTContributedByInvestorBEP20Val = ethers.utils.formatEther(totalUSDTContributedByInvestorBEP20)
     setTotalUSDTContributedByInvestorBEP20(parseFloat(totalUSDTContributedByInvestorBEP20Val))

     const totalUSDTContributedByInvestorERC20 = await mainnetERC20Presale.totalUSDTContributedByInvestor(account)
     const totalUSDTContributedByInvestorERC20Val = ethers.utils.formatEther(totalUSDTContributedByInvestorERC20)
     setTotalUSDTContributedByInvestorERC20(parseFloat(totalUSDTContributedByInvestorERC20Val))

     const totalUSDTInvestedByUserOveral = parseFloat(totalUSDTContributedByInvestorBEP20Val) + parseFloat(totalUSDTContributedByInvestorERC20Val)
     setTotalUSDTContributedByInvestor(parseFloat(totalUSDTInvestedByUserOveral))

     const isAllowedToClaimTokensERC20 = await mainnetERC20Presale.isAllowedToClaimTokens(account)
     setIsAllowedToClaimTokensERC20(isAllowedToClaimTokensERC20)

     const isAllowedToClaimTokensBEP20 = await mainnetBEP20Presale.isAllowedToClaimTokens(account)
     setIsAllowedToClaimTokensBEP20(isAllowedToClaimTokensBEP20)


    }

   }

   useEffect(() => {
    webModalConnection.current = new Web3Modal({
      cacheProvider: true,
      providerOptions,
      //network: '' ,
      disableInjectedProvider: false,
      theme: 'dark',
      accentColor: 'blue',
      ethereum: {
        appName: 'web3Modal'
      }
    });
    //console.log(webModalConnection)
  },[web3Modal])

  useEffect(() => {
      if(!isConnected){
        loadBlockchainData();
      }
  }, [isConnected, INFURA_RPC])


  useEffect(() => {
      connectWalletOnPageLoad()
  }, []);

  useEffect(() => {
      if (connectionlibrary?.on) {
        const handleAccountsChanged = async(accounts) => {
          //console.log("accountsChanged", accounts);
          setConnectedAccount(accounts[0])
          setAccount(accounts[0]);
          setConnected(true);
          refreshAccountContributionData(accounts[0])
        };
      
        const handleChainChanged = async(chainId) => {
          try{
            //console.log(chainId.toString())
            //console.log("chainChanged", chainId);
          //console.log(chainId)
            const chain = parseInt(chainId)
            setChainId(chain);
            //setConnected(true);
            //console.log(chain)
            //localStorage.setItem('isWalletConnected',true) 
            window.location.reload()
            //localStorage?.getItem('isWalletConnected') === 'true'
            //await getSignerOrProvider()
          }catch(error){
            console.log(error)
          }
        };

        const handleDisconnect = () => {
          //console.log("disconnected");
          disconnect();
        };
  
        connectionlibrary.on("accountsChanged", handleAccountsChanged);
        connectionlibrary.on("chainChanged", handleChainChanged);
        connectionlibrary.on("disconnect", handleDisconnect);
  
        return () => {
          if (connectionlibrary.removeListener) {
            connectionlibrary.removeListener("accountsChanged", handleAccountsChanged);
            connectionlibrary.removeListener("chainChanged", handleChainChanged);
            connectionlibrary.removeListener("disconnect", handleDisconnect);
          }
        };
      }
    }, [connectionlibrary,library,localStorage,chainId]);
  

    return (
        <PresaleContext.Provider value={{
          connectWallet, isConnected, provider, switchBinanceSmartChainNetwork, connectedAccount, network, chainId, account, connectionlibrary,
          disconnectWallet, truncateAddress, bep20PresaleContract, isPresaleActive, isPresalePaused, isPresaleClaimsOpen, pricePerToken,
          isAnActiveInvestor, totalTokensBoughtByInvestor, totalBNBInvestedByInvestor, totalTokensClaimedByInvestor,
          presaleDuration, totalTokensToBeSold, minPurchaseInWei, maxPurchaseInWei, tokenLockPeriod, totalTokensClaimed,
          totalTokensPurchased, totalUSDRaised, totalInvestors, totalBNBRaised, totalUSDTContributedByInvestor,
          isAllowedToClaimTokens, buyTokenWithBNB, buyTokenWithETH, switchToEthereumNetwork, totalUSDTRaised, 
          claimFromERC20Contribution,usdtContractInstance,busdContractInstance,
          claimFromBEP20Contribution, approveBEP20USDTStableCoin,approveBEP20BUSDStableCoin, executeBEP20StableCoinPurchase,
          userUSDTBalance,userBUSDBalance,mainnetBep20PresaleContract, mainnetERC20PresaleContract,
          isBEP20PresaleActive, isERC20PresaleActive, totalBEP20BNBRaised, totalERC20BNBRaised, isBE20PresalePaused,
          isERC20PresalePaused, totalInvestorsBEP20, totalInvestorsERC20, totalUSDRaisedBEP20, totalUSDRaisedERC20,
          totalUSDTRaisedERC20, totalUSDTRaisedBEP20, totalTokensPurchasedBEP20, totalTokensPurchasedERC20,
          totalTokensClaimedERC20, totalTokensClaimedBEP20,isPresaleClaimsOpenBEP20,isPresaleClaimsOpenERC20,
          isAnActiveInvestorERC20, isAnActiveInvestorBEP20, totalTokensBoughtByInvestorERC20, totalTokensBoughtByInvestorBEP20,
          totalBNBInvestedByInvestorBEP20, totalBNBInvestedByInvestorERC20, totalTokensClaimedByInvestorERC20, totalTokensClaimedByInvestorBEP20,
          totalUSDTContributedByInvestorERC20, totalUSDTContributedByInvestorBEP20, isAllowedToClaimTokensERC20, isAllowedToClaimTokensBEP20,
          eRC20USDTContractInstance, eRC20BUSDContractInstance, approveERC20USDTStableCoin, approveERC20BUSDStableCoin

        }}>
            {children}
        </PresaleContext.Provider>
    )
}
